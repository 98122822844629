import { createMuiTheme } from "@material-ui/core/styles";
import { red, amber, green } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#027502",
    },
    secondary: {
      main: "#ff9100",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#ffffff",
    },
    warning: {
      main: amber[500],
    },
    success: {
      main: green[700],
    },
  },
  typography: {
    fontSize: 13,
  },
});

export default theme;
