import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  ListItemIcon,
  SwipeableDrawer,
  Badge,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircleOutlined,
  ExitToAppOutlined,
  HelpOutline,
  HomeOutlined,
  DynamicFeedOutlined,
  QuestionAnswerOutlined,
  AssignmentOutlined,
  Tab,
  PublicOutlined,
  MailOutline,
  ListAltOutlined,
  SupervisedUserCircle,
  Public,
} from "@material-ui/icons";
import { logout } from "../actions";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  drawerDesktop: {
    display: "none",
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: drawerWidth,
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerPaperDesktop: {
    width: drawerWidth,
    zIndex: 1,
    backgroundColor: "transparent",
  },

  content: {
    flexGrow: 1,
    marginTop: 60,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },
  },
}));

const Navbar = (props) => {
  const { children, logout, product, query } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation().pathname;

  const renderSideBar = () => {
    return (
      <React.Fragment>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <HomeOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Home</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/mom" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/mom"
        >
          <ListItemIcon>
            <ListAltOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">MOM List</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/staff-list" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/staff-list"
        >
          <ListItemIcon>
            <SupervisedUserCircle color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Staff List</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/query" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/query"
        >
          <ListItemIcon>
            <MailOutline color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Query</Typography>}
          />
          <Badge
            badgeContent={query.filter((q) => !q.read).length}
            color="secondary"
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/product" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/product"
        >
          <ListItemIcon>
            <Tab color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Product</Typography>}
          />
          <Badge
            badgeContent={product.filter((a) => !a.status).length}
            color="secondary"
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/bulk" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/bulk"
        >
          <ListItemIcon>
            <DynamicFeedOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Bulk Product</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/product-order" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/product-order"
        >
          <ListItemIcon>
            <Tab color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Product Order</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/bulk-order" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/bulk-order"
        >
          <ListItemIcon>
            <DynamicFeedOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="primary">Bulk Product Order</Typography>
            }
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/news" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/news"
        >
          <ListItemIcon>
            <PublicOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">MOM News</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/notice" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/notice"
        >
          <ListItemIcon>
            <AssignmentOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">MOM Notice</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/advertisement" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/advertisement"
        >
          <ListItemIcon>
            <Public color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="primary">Public Advertisement</Typography>
            }
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/faq" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/faq"
        >
          <ListItemIcon>
            <QuestionAnswerOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">FAQ</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/helpline" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/helpline"
        >
          <ListItemIcon>
            <HelpOutline color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Helpline</Typography>}
          />
        </ListItem>
        <ListItem
          style={{
            borderRadius: 3,
            backgroundColor: location === "/admin" && "#edf2f1",
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/admin"
        >
          <ListItemIcon>
            <AccountCircleOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">Administrator</Typography>}
          />
        </ListItem>
      </React.Fragment>
    );
  };
  const menuId = "primary-search-account-menu";

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{ marginTop: 34 }}
      >
        <MenuItem
          component={Link}
          dense
          to="/settings"
          onClick={handleMenuClose}
        >
          <ListItemIcon>
            <AccountCircleOutlined />
          </ListItemIcon>
          <Typography>Account Settings</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            logout();
          }}
          dense
        >
          <ListItemIcon>
            <ExitToAppOutlined />
          </ListItemIcon>
          <Typography>Sign Out</Typography>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          zIndex: 2,
          boxShadow: "0px 1px 2px #ddd",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={(e) => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            className={classes.title}
            alt="Logo"
            src="/logo512.png"
            style={{ height: 50 }}
          />

          <div className={classes.grow} />

          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="secondary"
          >
            <Avatar style={{ height: 25, width: 25 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu()}

      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />

        <List>{renderSideBar()}</List>
      </SwipeableDrawer>

      <Drawer
        className={classes.drawerDesktop}
        anchor="left"
        variant="persistent"
        open={true}
        classes={{
          paper: classes.drawerPaperDesktop,
        }}
      >
        <div style={{ height: 50 }} />

        <List>{renderSideBar()}</List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    product: state.product,
    query: state.query,
  };
};
export default connect(mapStateToProps, { logout })(Navbar);
