import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import {
  fetchDecoded,
  fetchProducts,
  fetchProfile,
  fetchQueries,
  checkAuth,
} from "./actions";
import AdminNavbar from "./components/AdminNavbar";
import PublicNavbar from "./components/PublicNavbar";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/cms/Dashboard"));
const Administrators = lazy(() => import("./pages/cms/Administrators"));
const Bulk = lazy(() => import("./pages/cms/Bulk"));
const FAQ = lazy(() => import("./pages/cms/FAQ"));
const MOM = lazy(() => import("./pages/cms/MOM"));
const StaffList = lazy(() => import("./pages/cms/StaffList"));
const Helpline = lazy(() => import("./pages/cms/Helpline"));
const News = lazy(() => import("./pages/cms/News"));
const Notice = lazy(() => import("./pages/cms/Notice"));
const Product = lazy(() => import("./pages/cms/Product"));
const RetailProduct = lazy(() => import("./pages/RetailProduct"));
const BulkProduct = lazy(() => import("./pages/BulkProduct"));
const About = lazy(() => import("./pages/About"));
const NewsPublic = lazy(() => import("./pages/News"));
const FAQPublic = lazy(() => import("./pages/FAQ"));
const Contact = lazy(() => import("./pages/Contact"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Query = lazy(() => import("./pages/cms/Query"));
const Settings = lazy(() => import("./pages/cms/Settings"));
const Profile = lazy(() => import("./pages/Profile"));
const OrderHistory = lazy(() => import("./pages/OrderHistory"));
const ProductOrder = lazy(() => import("./pages/cms/ProductOrder"));
const BulkOrder = lazy(() => import("./pages/cms/BulkOrder"));
const Advertisement = lazy(() => import("./pages/cms/Advertisement"));
// const Crops = lazy(() => import("./pages/Crops"));
// const Wholesale = lazy(() => import("./pages/Wholesale"));
// const Weather = lazy(() => import("./pages/Weather"));
// const News = lazy(() => import("./pages/News"));
// const Tutorial = lazy(() => import("./pages/Tutorial"));
// const Helpline = lazy(() => import("./pages/cms/Helpline"));
// const Topic = lazy(() => import("./pages/Topic"));
// const Query = lazy(() => import("./pages/Query"));
// const Farmer = lazy(() => import("./pages/Farmer"));
// const Village = lazy(() => import("./pages/Village"));
// const Settings = lazy(() => import("./pages/cms/Settings"));
const Notfound = lazy(() => import("./pages/Notfound"));

const App = (props) => {
  const {
    fetchDecoded,
    fetchProducts,
    fetchProfile,
    fetchQueries,
    checkAuth,
    auth,
  } = props;

  useEffect(() => {
    checkAuth();
    if (auth === 1) {
      fetchDecoded();
      fetchProducts();
      fetchProfile();
      fetchQueries();
    }
    if (auth === 3) {
      fetchProfile();
    }
  }, [auth]);

  if (auth === 1) {
    return (
      <AdminNavbar>
        <Suspense
          fallback={
            <div
              style={{
                marginTop: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/cms" exact component={Dashboard} />
            <Route path="/query" exact component={Query} />
            <Route path="/product" exact component={Product} />
            <Route path="/bulk" exact component={Bulk} />
            <Route path="/news" exact component={News} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/notice" exact component={Notice} />
            <Route path="/helpline" exact component={Helpline} />
            <Route path="/mom" exact component={MOM} />
            <Route path="/staff-list" exact component={StaffList} />
            <Route path="/advertisement" exact component={Advertisement} />
            <Route path="/admin" exact component={Administrators} />
            <Route path="/product-order" exact component={ProductOrder} />
            <Route path="/bulk-order" exact component={BulkOrder} />
            <Route path="/settings" exact component={Settings} />
            <Route path="*" exact component={Notfound} />
          </Switch>
        </Suspense>
      </AdminNavbar>
    );
  } else if (auth === 3 || auth === 0) {
    return (
      <PublicNavbar>
        <Suspense
          fallback={
            <div
              style={{
                marginTop: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/cms" exact component={Login} />
            <Route path="/product/retail" exact component={RetailProduct} />
            <Route path="/product/bulk" exact component={BulkProduct} />
            <Route path="/about" exact component={About} />
            <Route path="/news" exact component={NewsPublic} />
            <Route path="/faq" exact component={FAQPublic} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/order-history" exact component={OrderHistory} />
            {/* <Route path="/product" exact component={Product} />
          <Route path="/bulk" exact component={Bulk} />
          <Route path="/news" exact component={News} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/settings" exact component={Settings} /> */}
            <Route path="*" exact component={Notfound} />
          </Switch>
        </Suspense>
      </PublicNavbar>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 400,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps, {
  fetchDecoded,
  fetchProducts,
  fetchProfile,
  fetchQueries,
  checkAuth,
})(App);
