import { combineReducers } from "redux";
import auth from "./auth";
import decoded from "./decoded";
import product from "./product";
import profile from "./profile";
import query from "./query";

export default combineReducers({
  auth: auth,
  decoded: decoded,
  product: product,
  profile: profile,
  query: query,
});
