import React from "react";
import { Typography, Grid } from "@material-ui/core";

const SocialLinks = () => {
  return (
    <React.Fragment>

      <div style={{ marginTop: 100, marginBottom: 30, display: "flex", flexDirection: "column" }}>
        <img alt="MOVCDNER" src="/movcdner.png" style={{ alignSelf: "center", marginBottom: 20, width: 300 }} />
        <Typography align="center" style={{ fontWeight: 600 }}>
          Follow us on
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{ width: 240, marginTop: 10 }}
          >
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} component="a" href="https://www.facebook.com/missionorganicmizoram" target="_blank">
              <img alt="Facebook" src="/fb.png" style={{ width: 30, height: 30 }} />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} component="a" href="https://www.instagram.com/missionorganicmizoram/" target="_blank">
              <img alt="Instagram" src="/ig.png" style={{ width: 30, height: 30 }} />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} component="a" href="https://www.youtube.com/channel/UCjI78MQRKEQlLjAV9Q4CfRA/featured" target="_blank">
              <img alt="Youtube" src="/yt.png" style={{ width: 30, height: 30 }} />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} component="a" href="https://twitter.com/OrganicMizoram" target="_blank">
              <img alt="Twitter" src="/twitter.png" style={{ width: 30, height: 30 }} />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SocialLinks;
