export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_PRODUCT":
      return action.payload;
    case "ADD_PRODUCT":
        return [...state, action.payload];
        case "UPDATE_PRODUCT":
          return state.map((s) =>
            s.id === action.payload.id ? action.payload : s
          );
    case "REMOVE_PRODUCT":
      return state.filter((s) => s.id !== action.payload);
    case "CLEAR_PRODUCT":
      return [];
    default:
      return state;
  }
};
