export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_QUERY":
      return action.payload;
    case "UPDATE_QUERY":
      return state.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    case "REMOVE_QUERY":
      return state.filter((s) => s.id !== action.payload);
    case "CLEAR_QUERY":
      return [];
    default:
      return state;
  }
};
