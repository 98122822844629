import React, { lazy, Suspense, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Typography,
  SwipeableDrawer,
  Container,
  Button,
  Paper,
  MenuList,
  Divider,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ArrowDropDown,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { logout } from "../actions";
import SocialLinks from "./SocialLinks";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 2,
    borderBottom: "2px solid #027502",
    boxShadow: "0px 1px 2px #ddd",
    backgroundColor: "#fff",
    height: 110,
    [theme.breakpoints.down("xs")]: {
      height: 55,
    },
  },
  appBar_MenuButton: {
    marginLeft: -30,
    marginRight: 30,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  appBar_Logo: {
    marginTop: 10,
    position: "absolute",
    height: 100,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      height: 40,
      marginTop: 0,
      marginLeft: 50
    },
  },
  appBar_Nav: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerPaperDesktop: {
    width: drawerWidth,
    zIndex: 1,
    backgroundColor: "transparent",
  },

  content: {
    flexGrow: 1,
    marginTop: 140,
    [theme.breakpoints.down("xs")]: {
      marginTop: 55,
      flexGrow: 1,

    },
  },
}));

const Navbar = (props) => {
  const { children, auth, logout } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openProductMobile, setOpenProductMobile] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false);
  const Login = lazy(() => import("./Login"));
  const Registration = lazy(() => import("./Registration"));
  const renderSideBar = () => {
    return (
      <React.Fragment>
        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/"
        >
          <ListItemText
            primary={<Typography color="primary">Home</Typography>}
          />
        </ListItem>
        <Divider />
        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/about"
        >
          <ListItemText
            primary={<Typography color="primary">About</Typography>}
          />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => setOpenProductMobile(!openProductMobile)}
        >
          <ListItemText
            primary={<Typography color="primary">Product</Typography>}
          />
          {openProductMobile ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProductMobile} timeout="auto" unmountOnExit>
          <ListItem
            button
            component={Link}
            to="/product/retail"
            onClick={() => setOpenDrawer(false)}
            style={{ paddingLeft: 30 }}
          >
            <ListItemText
              primary={
                <Typography color="primary">Retail Products</Typography>
              }
            />
          </ListItem>

          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/product/bulk"
              onClick={() => setOpenDrawer(false)}
              style={{ paddingLeft: 30 }}
            >
              <ListItemText
                primary={<Typography color="primary">Bulk Products</Typography>}
              />
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/news"
        >
          <ListItemText
            primary={<Typography color="primary">News</Typography>}
          />
        </ListItem>
        <Divider />
        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/faq"
        >
          <ListItemText
            primary={<Typography color="primary">FAQ</Typography>}
          />
        </ListItem>
        <Divider />
        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/contact"
        >
          <ListItemText
            primary={<Typography color="primary">Contact</Typography>}
          />
        </ListItem>
        <Divider />
        <ListItem
          style={{
            borderRadius: 3,
          }}
          button
          dense
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to="/privacy-policy"
        >
          <ListItemText
            primary={<Typography color="primary">Privacy Policy</Typography>}
          />
        </ListItem>
        <Divider />
        {auth ? (
          <>
            <ListItem
              style={{
                borderRadius: 3,
              }}
              button
              dense
              onClick={() => setOpenDrawer(false)}
              component={Link}
              to="/order-history"
            >
              <ListItemText
                primary={<Typography color="primary">Order History</Typography>}
              />
            </ListItem>
            <Divider />
            <ListItem
              style={{
                borderRadius: 3,
              }}
              button
              dense
              onClick={() => setOpenDrawer(false)}
              component={Link}
              to="/profile"
            >
              <ListItemText
                primary={<Typography color="primary">My Profile</Typography>}
              />
            </ListItem>
            <Divider />
            <ListItem
              style={{
                borderRadius: 3,
              }}
              button
              dense
              onClick={() => {
                if (window.confirm(`Are you sure you want to logout?`)) {
                  return logout();
                }
              }}
            >
              <ListItemText
                primary={<Typography color="primary">Logout</Typography>}
              />
            </ListItem>
            <Divider />
          </>
        ) : (
            <>
              <ListItem
                style={{
                  borderRadius: 3,
                }}
                button
                dense
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/cms"
              >
                <ListItemText
                  primary={
                    <Typography color="primary">Admin Login</Typography>
                  }
                />
              </ListItem>
              <ListItem
                style={{
                  borderRadius: 3,
                }}
                button
                dense
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenLoginDialog(true);
                }}
              >
                <ListItemText
                  primary={
                    <Typography color="primary">Login / Register</Typography>
                  }
                />
              </ListItem>
              <Divider />
            </>
          )}
      </React.Fragment>
    );
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar className={classes.appBar} position="absolute">
        <Container>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.appBar_MenuButton}
              aria-label="open drawer"
              onClick={(e) => {
                setOpenDrawer(true);
              }}
            >
              <MenuIcon style={{ color: "#027502" }} />
            </IconButton>
            <Link to="/" className={classes.appBar_Logo}>
              <img
                className={classes.appBar_Logo}
                alt="Logo"
                src="/logo512.png"

              />
            </Link>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.appBar_Nav}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ flex: 1 }}>
                  {auth ? (
                    <>
                      <Button
                        style={{
                          fontSize: 15,
                          fontWeight: 500, textTransform: "capitalize",
                          marginTop: 20,
                        }}
                        component={Link}
                        to="/order-history"
                      >
                        Order History
                      </Button>
                      <Button
                        style={{
                          fontSize: 15,
                          fontWeight: 500, textTransform: "capitalize",
                          marginTop: 20,
                        }}
                        component={Link}
                        to="/profile"
                      >
                        My Profile
                      </Button>
                      <Button
                        style={{
                          fontSize: 15,
                          fontWeight: 500, textTransform: "capitalize",
                          marginTop: 20,
                        }}
                        onClick={() => {
                          if (
                            window.confirm(`Are you sure you want to logout?`)
                          ) {
                            return logout();
                          }
                        }}
                      >
                        Logout
                      </Button>
                    </>
                  ) : (
                      <>
                        <Button
                          style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginTop: 20, marginRight: 20 }}
                          component={Link}
                          to="/cms"
                        >
                          Admin Login
                    </Button>
                        <Button
                          style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginTop: 20 }}
                          onClick={() => {
                            setOpenLoginDialog(true);
                          }}
                        >
                          Login/Register
                    </Button>
                      </>
                    )}
                </div>
                <div style={{ flex: 1 }}>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                    component={Link}
                    to="/"
                  >
                    Home
                  </Button>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                    component={Link}
                    to="/about"
                  >
                    About Us
                  </Button>
                  <ClickAwayListener onClickAway={() => setOpenProduct(false)}>
                    <div style={{ display: "inline-block" }}>
                      <Button
                        onClick={() => setOpenProduct(true)}
                        style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                      >
                        Product <ArrowDropDown />
                      </Button>
                      {openProduct ? (
                        <Paper
                          style={{
                            position: "absolute",
                          }}
                        >
                          <MenuList style={{ padding: 0 }}>
                            <MenuItem
                              dense
                              onClick={() => setOpenProduct(false)}
                              component={Link}
                              to="/product/retail"
                            >
                              <Typography>Retail Products</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => setOpenProduct(false)}
                              dense
                              component={Link}
                              to="/product/bulk"
                            >
                              <Typography>Bulk Products</Typography>
                            </MenuItem>

                          </MenuList>
                        </Paper>
                      ) : null}
                    </div>
                  </ClickAwayListener>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                    component={Link}
                    to="/news"
                  >
                    News
                  </Button>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                    component={Link}
                    to="/faq"
                  >
                    FAQ
                  </Button>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", marginRight: 10 }}
                    component={Link}
                    to="/contact"
                  >
                    Contact
                  </Button>
                  <Button
                    style={{ fontSize: 15, fontWeight: 500, textTransform: "capitalize", }}
                    component={Link}
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>

        </Container>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />

        <List>{renderSideBar()}</List>
      </SwipeableDrawer>

      <main className={classes.content}>{children}
        <SocialLinks />
      </main>
      <footer style={{ backgroundColor: "#536E5F", padding: 10 }}>
        <Typography align="center" style={{ color: "#fff" }}>
          Copyright © Mission Organic Mizoram {new Date().getFullYear()}
        </Typography>
        <Typography align="center" style={{ color: "#fff" }}>
          Developed by Hereus
        </Typography>
      </footer>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openLoginDialog}
        onClose={() => {
          setOpenLoginDialog(false);
        }}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <Suspense
            fallback={
              <div
                style={{
                  marginTop: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <Login
              setOpenRegistrationDialog={setOpenRegistrationDialog}
              setOpenLoginDialog={setOpenLoginDialog}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openRegistrationDialog}
        onClose={() => {
          setOpenRegistrationDialog(false);
        }}
      >
        <DialogTitle>Registration</DialogTitle>
        <DialogContent>
          <Suspense
            fallback={
              <div
                style={{
                  marginTop: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <Registration
              setOpenRegistrationDialog={setOpenRegistrationDialog}
              setOpenLoginDialog={setOpenLoginDialog}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    decoded: state.decoded,
    query: state.query,
    productOrder: state.productOrder,
    societyRequest: state.societyRequest,
    cropRequest: state.cropRequest,
  };
};
export default connect(mapStateToProps, { logout })(Navbar);
