import jwt from "jsonwebtoken";
import api from "../api/api";
import publickey from "../api/publickey";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("mom-admin")) {
      jwt.verify(
        localStorage.getItem("mom-admin"),
        publickey,
        async (err, decoded) => {
          if (!err) {
            return dispatch({
              type: "AUTH_SUCCESS_ADMIN",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else if (localStorage.getItem("mom-customer")) {
      jwt.verify(
        localStorage.getItem("mom-customer"),
        publickey,
        async (err, decoded) => {
          if (!err) {
            return dispatch({
              type: "AUTH_SUCCESS_CUSTOMER",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const logout = () => async (dispatch) => {
  window.location.href = "/";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};

export const fetchDecoded = () => (dispatch) => {
  let token;
  if (localStorage.getItem("mom-admin")) {
    token = localStorage.getItem("mom-admin");
  } else if (localStorage.getItem("mom-customer")) {
    token = localStorage.getItem("mom-customer");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }

  jwt.verify(token, publickey, (err, decoded) => {
    if (!err) {
      try {
        return dispatch({
          type: "FETCH_DECODED",
          payload: decoded,
        });
      } catch (error) {
        return dispatch({
          type: "CLEAR_DECODED",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchProfile = () => async (dispatch) => {
  if (localStorage.getItem("mom-admin")) {
    jwt.verify(
      localStorage.getItem("mom-admin"),
      publickey,
      async (err, decoded) => {
        if (!err) {
          try {
            const response = await api.get(`/api/admin/${decoded.id}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("mom-admin"),
              },
            });
            return dispatch({
              type: "FETCH_PROFILE",
              payload: response.data.data,
            });
          } catch (error) {
            return dispatch({
              type: "CLEAR_PROFILE",
            });
          }
        } else {
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    );
  } else if (localStorage.getItem("mom-customer")) {
    jwt.verify(
      localStorage.getItem("mom-customer"),
      publickey,
      async (err, decoded) => {
        if (!err) {
          try {
            const response = await api.get(`/api/customer/${decoded.id}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("mom-customer"),
              },
            });
            return dispatch({
              type: "FETCH_PROFILE",
              payload: response.data.data[0],
            });
          } catch (error) {
            return dispatch({
              type: "CLEAR_PROFILE",
            });
          }
        } else {
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    );
  } else {
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/product`);
    return dispatch({
      type: "FETCH_PRODUCT",
      payload: response.data.data,
    });
  } catch (error) {
    return dispatch({
      type: "CLEAR_PRODUCT",
    });
  }
};

export const fetchQueries = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("mom-admin")) {
    token = localStorage.getItem("mom-admin");
  } else {
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/query`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_QUERY",
          payload: response.data.data,
        });
      } catch (error) {
        return dispatch({
          type: "CLEAR_QUERY",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};